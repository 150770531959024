<template>
  <div>
    <v-row>
      <v-col>
        <v-chip
          class="ma-2"
          label
          text-color="primary"
          color="primary"
          outlined
          right
          to="/components/tool/tab-4"
          @click="alert = !alert"
        >
          {{ $t("message.how_to_register") }}
        </v-chip>
        <!-- <v-chip
          v-clipboard:copy="onTag"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          dark
          class="ma-2"
          label
          text-color="primary"
          color="primary"
          outlined
        >
          <v-icon left>
            mdi-content-copy
          </v-icon>
          {{ $t("message.copy_commission_open_tag") }}
        </v-chip> -->
        <!-- <v-chip
          v-clipboard:copy="offTag"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          dark
          class="ma-2"
          label
          text-color="primary"
          color="primary"
          outlined
        >
          <v-icon left>
            mdi-content-copy
          </v-icon>
          {{ $t("message.tweet_commission_closed") }}
        </v-chip> -->
        <CopyCommissionOpen />
        <CommissionOpen />
        <CommissionClosed />
        <CommissionDel />
      </v-col>
    </v-row>
    <v-tabs
      v-model="tab"
      background-color="#1E1E1E"
      centered
      dark
      icons-and-text
    >
      <v-tabs-slider />

      <v-tab
        href="#tab-1"
        @click="updateTab(1)"
      >
        {{ $t("message.commission_tab1") }}
        <!-- <v-icon>mdi-play-circle-outline</v-icon> -->
        <v-icon>mdi-door-open</v-icon>
      </v-tab>

      <v-tab
        href="#tab-2"
        @click="updateTab(2)"
      >
        {{ $t("message.commission_tab2") }}
        <!-- <v-icon>mdi-stop-circle-outline</v-icon> -->
        <v-icon>mdi-door-closed-lock</v-icon>
      </v-tab>

      <!-- <v-tab href="#tab-3">
            {{ $t("message.technique_tweets") }}
            <v-icon>mdi-account-box</v-icon>
          </v-tab> -->
    </v-tabs>

    <v-tabs-items
      v-model="tab"
    >
      <v-tab-item
        :value="'tab-1'"
      >
        <v-data-iterator
          sort-by="update_date"
          :sort-desc="sortDesc"
          :items="items_open"
          :search="search"
          :footer-props="{ 'items-per-page-options': [20, 30, 40]}"
          :items-per-page="20"
          :page="page"
          :options="options"
          @options="optionsSave"
          @update:page="scrollTop"
        >
          <template v-slot:header>
            <v-toolbar
              dark
              color="gray darken-3"
              class="mb-1"
            >
              <v-text-field
                v-model="search"
                clearable
                flat
                solo-inverted
                hide-details
                prepend-inner-icon="mdi-magnify"
                :label="$t('message.commission_search_from_tab1')"
              />
              <template v-if="$vuetify.breakpoint.mdAndUp">
                <v-spacer />
                <v-btn-toggle
                  v-model="sortDesc"
                  mandatory
                >
                  <v-btn
                    large
                    depressed
                    color="gray"
                    :value="false"
                  >
                    <v-icon>mdi-arrow-up</v-icon>
                  </v-btn>
                  <v-btn
                    large
                    depressed
                    color="gray"
                    :value="true"
                  >
                    <v-icon>mdi-arrow-down</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </template>
            </v-toolbar>
          </template>

          <template v-slot:default="props">
            <v-container
              id="user-profile-view"
              fluid
              tag="section"
            >
              <v-row
                justify="center"
              >
                <v-col
                  v-for="item in props.items"
                  :key="item.id_str"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <v-card
                    min-width="330"
                  >
                    <v-img
                      :src="item.user_profile_image_url_https_400x400"
                    />

                    <v-card-text class="text-center">
                      <v-chip
                        v-if="item.update_date >= targetDate"
                        outlined
                        color="white"
                        text-color="#e91e63"
                      >
                        <v-icon>
                          mdi-alert-circle-outline
                        </v-icon>
                        UPDATE
                      </v-chip>
                      <h6 class="text-h6 mb-2 text--secondary">
                        @{{ item.user_screen_name }}
                      </h6>

                      <h4 class="text-h4 mb-3 text--primary">
                        {{ item.user_name }}
                      </h4>

                      <p class="text--secondary">
                        {{ item.user_description }}
                      </p>
                      <tweet
                        :id="item.id_str"
                        error-message=" "
                        :options="{ align: 'center' }"
                        @TweetError="notShow(item)"
                      >
                        <spinner />
                      </tweet>
                      <router-link
                        :to="{ name: 'Artwork', params: { id: item.user_id_str } }"
                      >
                        <v-btn
                          class="mr-0"
                          color="primary"
                          min-width="100"
                          rounded
                        >
                          {{ $t("message.view_the_artwork") }}
                        </v-btn>
                      </router-link>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-iterator>
        <div />
      </v-tab-item>
      <v-tab-item
        :value="'tab-2'"
      >
        <v-data-iterator
          sort-by="update_date"
          :sort-desc="sortDesc"
          :items="items_closed"
          :search="search"
          :footer-props="{ 'items-per-page-options': [20, 30, 40]}"
          :items-per-page="20"
          :page="page"
          :options="options"
          @options="optionsSave"
          @update:page="scrollTop"
        >
          <template v-slot:header>
            <v-toolbar
              dark
              color="gray darken-3"
              class="mb-1"
            >
              <v-text-field
                v-model="search"
                clearable
                flat
                solo-inverted
                hide-details
                prepend-inner-icon="mdi-magnify"
                :label="$t('message.commission_search_from_tab2')"
              />
              <template v-if="$vuetify.breakpoint.mdAndUp">
                <v-spacer />
                <v-btn-toggle
                  v-model="sortDesc"
                  mandatory
                >
                  <v-btn
                    large
                    depressed
                    color="gray"
                    :value="false"
                  >
                    <v-icon>mdi-arrow-up</v-icon>
                  </v-btn>
                  <v-btn
                    large
                    depressed
                    color="gray"
                    :value="true"
                  >
                    <v-icon>mdi-arrow-down</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </template>
            </v-toolbar>
          </template>

          <template v-slot:default="props">
            <v-container
              id="user-profile-view"
              fluid
              tag="section"
            >
              <v-row
                justify="center"
              >
                <v-col
                  v-for="item in props.items"
                  :key="item.id_str"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <v-card>
                    <v-img
                      :src="item.user_profile_image_url_https_400x400"
                    />

                    <v-card-text class="text-center">
                      <v-chip
                        v-if="item.update_date >= targetDate"
                        outlined
                        color="white"
                        text-color="#e91e63"
                      >
                        <v-icon>
                          mdi-alert-circle-outline
                        </v-icon>
                        UPDATE
                      </v-chip>
                      <h6 class="text-h6 mb-2 text--secondary">
                        @{{ item.user_screen_name }}
                      </h6>

                      <h4 class="text-h4 mb-3 text--primary">
                        {{ item.user_name }}
                      </h4>

                      <p class="text--secondary">
                        {{ item.user_description }}
                      </p>

                      <router-link
                        :to="{ name: 'Artwork', params: { id: item.user_id_str } }"
                      >
                        <v-btn
                          class="mr-0"
                          color="primary"
                          min-width="100"
                          rounded
                        >
                          {{ $t("message.view_the_artwork") }}
                        </v-btn>
                      </router-link>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-iterator>
        <div />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  import { Tweet } from 'vue-tweet-embed'
  import Spinner from 'vue-simple-spinner'
  import axios from 'axios'

  // import Ads from './Ads'
  // import Spinner from 'vue-simple-spinner'
  export default {
    name: 'CommissionView',
    metaInfo: {
      title: 'Commission',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'keywords', content: 'ff14 ハウジング,ハウジング ff14,ff14 housing,housing ff14,ff14ハウジング,ff14housing,ハウジング,ハウジングエデン,ff14,ファイナルファンタジー,housing,ffxiv,final fantasy,Housing Eden,housing eden' },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://e-d-e-n.site' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        { name: 'description', content: 'FFXIV Housing Edenはファイナルファンタジー14の最新ハウジング情報を世界レベルでお届けするサイトです。今話題のツイートはもちろん、バズツイート、ハウジンガー名鑑、創作家具レシピまで網羅しています。' },
        { property: 'og:image', content: 'https://e-d-e-n.site/twitter_card.jpg?3' },
      ],
    },
    components: {
      Tweet,
      Spinner,
      // Ads,
    },
    data: function () {
      return {
        items_open: [],
        items_closed: [],
        // selectedId: "",
        sortDesc: true,
        search: '',
        sns: {
          twitter: 'https://twitter.com/intent/tweet?hashtags=HousingEden',
        },
        alert: false,
        options: {},
        page: 1,
        targetDate: '',
        onTag: '#HousingEdenCommissionOpen',
        offTag: '@tos #HousingEdenCommissionClosed',
        tweetid: '1405628902014218242',
        tab: '',
        path: '',
      }
    },
    watch: {
      items_open: function () {
        this.$nextTick(() => {
          var positionY = sessionStorage.getItem('positionY')
          setTimeout(function () {
            scrollTo(0, positionY)
          }, 500)
        })
      },
      items_closed: function () {
        this.$nextTick(() => {
          var positionY = sessionStorage.getItem('positionY')
          setTimeout(function () {
            scrollTo(0, positionY)
          }, 500)
        })
      },
    },
    created: function () {
      this.tab = `${this.$route.params.tabid}`

      // 日付をYYYY-MM-DDの書式で返すメソッド
      function formatDate (dt) {
        var y = dt.getFullYear()
        var m = ('00' + (dt.getMonth() + 1)).slice(-2)
        var d = ('00' + dt.getDate()).slice(-2)
        return (y + '-' + m + '-' + d)
      }
      this.targetDate = new Date()
      this.targetDate = formatDate(new Date(this.targetDate.getFullYear(), this.targetDate.getMonth(), this.targetDate.getDate() - 2))
      // console.log(this.targetDate)
      // console.log(this.$store.commissionItems)
      if (!this.$store.commissionItemsOn) {
        axios.get(`../../commission/commission_open.json?timestamp=${new Date().getTime()}`).then(res => {
          this.items_open = res.data
          this.$store.commissionItemsOn = res.data
          // res.data.forEach(data => {
          //   let inputdata;
          //   inputdata = data.quoted_status
          //   inputdata.user_name = data.quoted_status.user.name;
          //   inputdata.user_screen_name = data.quoted_status.user.screen_name;
          //   inputdata.user_user_id_str = data.quoted_status.user.user_id_str;
          //   this.items.push(inputdata);
          // });
        })
      } else { this.items_open = this.$store.commissionItemsOn }

      if (!this.$store.commissionItemsOff) {
        axios.get(`../../commission/commission_closed.json?timestamp=${new Date().getTime()}`).then(res => {
          this.items_closed = res.data
          this.$store.commissionItemsOff = res.data
          // res.data.forEach(data => {
          //   let inputdata;
          //   inputdata = data.quoted_status
          //   inputdata.user_name = data.quoted_status.user.name;
          //   inputdata.user_screen_name = data.quoted_status.user.screen_name;
          //   inputdata.user_user_id_str = data.quoted_status.user.user_id_str;
          //   this.items.push(inputdata);
          // });
        })
      } else { this.items_closed = this.$store.commissionItemsOff }

      if (this.$store.commissionOptions) {
        this.options = this.$store.commissionOptions
      }
      // console.log(this.$store.page)
      this.page = this.$store.commissionPage
      // console.log(this.$store.page)
    },
    methods: {
      scrollTop: function (event) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        // console.log(event)
        this.$store.commissionPage = event
      },
      optionsSave: function (event) {
        // console.log(event)
        this.$store.commissionOptions = event
      },
      updateTab: function (n) {
        this.path = '/#/components/commission/tab-' + n
        history.replaceState('', '', this.path)
        this.tab = `${this.$route.params.tabid}`
      },
      // 削除済みツイートの排除
      notShow: function (item) {
        // 指定されたindexの要素を1つ削除します。
        // this.indexs.push(index)
        // setTimeout(() => {
        // console.log('item:' + item)
        // console.log('items_num:' + this.items_num)
        // console.log('items.length:' + this.items.length)
        // this.items.splice(index - (this.items_num - this.items.length - 1), 1)
        // 要素を削除する
        // this.items.some(function (v, i, item) {
        //   if (v === item) this.items.splice(i, 1)
        // })
        var result = this.items_open.filter(function (i) {
          return i !== item
        })
        this.items_open = result
        // }, 1000)

        // console.log(index)
        // this.indexs.push(index)
        // console.log(this.indexs)
      },
    },
  }
</script>

<style>
    circle {
      fill: #e91e63;
      /* stroke: #333; */
    }
</style>
